<template>
  <vx-card :title="title" :subtitle="workoutsNum" no-shadow no-radius collapseAction>
    <ul class="today-workouts-list">
      <li
        class="today-workout flex items-center mb-4 cursor-pointer hover:text-primary"
        v-for="(workout, wkIdx) in workoutsToday"
        :key="wkIdx"
        @click="$router.push('/clients/' + workoutClientSlug(workout) + '/workouts')"
      >
        <div class="mr-3">
          <vs-avatar
            class="m-0"
            :src="getClientImageURL(workout.client_id)"
            :text="getClientUserName(workout.client_id)"
            size="35px"
          />
        </div>
        <div class="leading-tight">
          <p class="client_name">{{ getClientUserName(workout.client_id) }}</p>
          <span class="text-xs">{{ workout.title  }} </span> •
          <span class="text-xs">{{ getClientType(workout.client_id) }}</span>
        </div>
      </li>
    </ul>
  </vx-card>
</template>

<script>
//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";

import { mapState } from "vuex";

export default {
  mixins: [baseFun],

  props: ["title", "workoutsToday"],

  data() {
    return {

    }
  },

  computed: {
    workoutsNum() {
      return "共" + (this.workoutsToday && this.workoutsToday.length ? this.workoutsToday.length : 0) + "人";
    },
    ...mapState([
      "activeTrainer",
      "activeUser",
      "activeOrganization",
      "organizations",
      "trainers",
      "clients",
      "users",
      "groups",
      "workouts",
      "workout_items",
    ])
  },

  methods:{
    workoutClientSlug(workout) {
      if (!workout) return "";
      let client = this.getClient(workout.client_id);
      return client ? client.slug : "";
    }
  }
};
</script>

<style scoped>
.client_name{
  font-weight: bold
}
</style>
