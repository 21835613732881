<template>
  <vx-card>
    <!-- WORKOUTS USER -->
    <div class="workout-user flex w-full flex-start workout-item" noShadow noRadius>
      <!-- <div
        class="flex items-center cursor-pointer"
        @click="$router.push('/clients/' + workoutClientSlug(workout) + '/workouts/' + workout.id)"
      > -->
      <div class="flex items-center cursor-pointer" @click="showPopup=true">
        <div>
          <vs-avatar
            class="m-0"
            :src="workoutUserImgURL(workout)"
            :text="workoutUserName(workout)"
            size="45px"
          ></vs-avatar>
        </div>
        <div class="ml-4">
          <h4>
            {{workoutUserName(workout)}}
            <span>{{workout.title}}</span>
          </h4>
          <small>{{ localDate(workout.due) }} | 最后训练 {{ timePast(workout.updated_at) }} 以前</small>
        </div>
      </div>
    </div>

    <!-- WORKOUT_ITEMS -->
    <!-- hide warmup by  BUG report -->
    <!-- <vx-card class="workout-item" noShadow>
      <div class="flex w-full flex-start">
        <div>
          <feather-icon icon="ActivityIcon" svgClasses="fill-current" class />
        </div>
        <div class="flex w-full justify-between">
          <div class="exercise">
            <div class="text-lg ml-3 font-semibold">热身</div>
            <div class="text-lg ml-3">
              <pre>{{workout.warmup}}</pre>
            </div>
          </div>
          <div class="exercise-action">
            <vs-icon icon="done" size="small" color="green" class="cursor-pointer"></vs-icon>
          </div>
        </div>
      </div>
    </vx-card>-->

    <div
      v-for="(workout_item, wkiIdx) in workout_item_list(workout.id)"
      :key="wkiIdx"
      class="flex w-full flex-start workout-item"
    >
      <div>
        <!-- <feather-icon icon="FastForwardIcon" :badge="wkiIdx+1" /> -->
        <span class="item-idx">{{numberToChar(wkiIdx)}}</span>
      </div>
      <div class="flex w-full justify-between">
        <div class="exercise">
          <div class="text-lg ml-3 font-semibold">{{workout_item.name}}</div>
          <div class="text-lg ml-3">
            <pre>{{workout_item.info}}</pre>
          </div>
        </div>
        <div class="exercise-action">
          <!-- <vs-icon v-if="workout_item.state=='completed'" icon="done" size="small" color="green" class="cursor-pointer"></vs-icon> -->
          <vs-icon v-if="workout_item.state=='completed'" icon="done" size="small" color="green"></vs-icon>
          <vs-icon v-if="workout_item.state=='missed'" icon="close" size="small" color="red"></vs-icon>
          <vs-icon v-if="workout_item.state=='pending'" icon="remove" size="small" color="#444"></vs-icon>
        </div>
      </div>
    </div>

    <vs-popup fullscreen title :active.sync="showPopup">
      <WorkoutEdit
        v-if="workout"
        :showPopup="showPopup"
        :workout_org="workout"
        @hide="showPopup=false"
      />
    </vs-popup>
  </vx-card>
</template>

<script>
//common functions
import { mapState } from "vuex";
import baseFun from "@/views/components/coach/baseFunctions.js";

import WorkoutEdit from "../workout/WorkoutEdit.vue";

export default {
  mixins: [baseFun],

  props: ["workout"],

  data() {
    return {
      showPopup: false
    };
  },

  computed: {
    ...mapState([
      "activeTrainer",
      "activeUser",
      "activeOrganization",
      "users",
      "clients",
      "comments",
      "workout_items",
      "workouts"
    ])
  },

  methods: {
    workoutClientSlug(workout) {
      if (!workout) return "";
      let client = this.getClient(workout.client_id);
      return client ? client.slug : "";
    },

    workoutUserImgURL(workout) {
      if (!workout) return "";
      let client = this.getClient(workout.client_id);
      let sRtn = client ? this.getUserImageURL(client.user_id) : "";
      return sRtn;
    },

    workoutUserName(workout) {
      if (!workout) return "";
      let client = this.getClient(workout.client_id);
      let sRtn = client ? this.getUserName(client.user_id) : "";
      return sRtn;
    }
  },

  components: {
    WorkoutEdit
  }
};
</script>

<style scoped>
.exercise pre {
  font-size: 85%;
  color:#999;
}

.workout-user{
  padding: 1rem 1.5rem 0;
}
.workout-item {
  padding: 1rem 1.5rem 0;
}
.item-idx{
  width: 50px;
  background: #eef0f1;
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
}
</style>
