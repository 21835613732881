<!-- =========================================================================================
    File Name: Profile.vue
    Description: Profile Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div id="profile-page">
    <!-- COL AREA -->
    <div class="vx-row">
      <!-- COL 1 TODAY WORKOUTS-->
      <div class="vx-col w-full lg:w-1/4">
        <vx-card class="mt-base">
          <vx-card no-shadow no-radius>
            <h2>{{todayWeekName}}</h2>
            <span>{{todayDateName}}</span>
          </vx-card>
          <HomeWorkouts title="今日训练计划" :workoutsToday="workoutsToday" />
        </vx-card>
      </div>

      <!-- COL 2 STATUS -->
      <div class="vx-col w-full lg:w-1/2">
        <vx-card class="mt-base">
          <!--coach info-->
          <div class="coach-header flex justify-between mb-4">
            <div class="flex items-center">
              <div>
                <vs-avatar
                  v-if="activeUser.image_url"
                  class="user-profile-img"
                  :src="activeUser.image_url"
                  size="65px"
                />
                <vs-avatar v-else class="user-profile-img" :text="activeUser.name" size="65px" />
              </div>
              <div class="ml-4">
                <h2>你好，{{activeUser.name}}</h2>
              </div>
            </div>
            <div class>
              <NewClient
                :trainer_id="activeTrainer.id"
                :show="clientAddActive"
                @hide="clientAddActive=false"
                :organization="activeOrganization"
              />
              <vs-button icon="person_add" @click="clientAddOpen">添加学员</vs-button>
            </div>
          </div>
          <!-- <div class="coach-header flex justify-between mb-4">
            <div class="flex items-center">
              <vs-select class="client-group" label="切换用户组别" placeholder="切换用户组别">
                <vs-select-item
                  v-for="(group, gIdx) in groups"
                  :key="gIdx"
                  :value="group.id"
                  :text="group.name"
                />
              </vs-select>
            </div>
            <div class="flex border">
              <vs-checkbox>显示休息日</vs-checkbox>
            </div>
          </div>-->
        </vx-card>

        <!-- WORKOUTS  -->
        <!-- <div class="mt-base"> -->
        <div class="mt-base">
          <HomeWorkoutDetail class="workout-detail"
            v-for="(workout, wkIdx) in workoutsRecent"
            :key="wkIdx"
            :workout="workout"
          />
        </div>
      </div>

      <!-- COL 3 DUE SOON -->
      <div class="vx-col w-full lg:w-1/4">
        <vx-card class="mt-base">
          <!-- OVERALL COMPLIANCE RATES -->
          <div>
            <h4>用户计划完成率</h4>
            <div class="vx-row">
              <div
                :class="'vx-col w-full lg:w-1/4 full-rate '+complianceRateName(trainerComplianceRates[0])"
              >
                {{trainerComplianceRates[0]}}<sup>%</sup>
                <br />
                <span>7天</span>
              </div>
              <div
                :class="'vx-col w-full lg:w-1/4 full-rate '+complianceRateName(trainerComplianceRates[1])"
              >
                {{trainerComplianceRates[1]}}<sup>%</sup>
                <br />
                <span>30天</span>
              </div>
              <div
                :class="'vx-col w-full lg:w-1/4 full-rate '+complianceRateName(trainerComplianceRates[2])"
              >
                {{trainerComplianceRates[2]}}<sup>%</sup>
                <br />
                <span>90天</span>
              </div>
            </div>
          </div>

          <!-- DUE SOON -->
          <vx-card
            title="未来3天训练计划"
            :subtitle="`${workoutsReminders.length} 项`"
            noShadow
            noRadius
            collapseAction
          >
            <ul class="due-soon-list">
              <li
                class="due-soon flex items-center mb-4 cursor-pointer hover:text-primary"
                v-for="(workout, cIdx) in workoutsReminders"
                :key="cIdx"
                @click="$router.push('/clients/' + getClientSlug(workout.client_id) + '/workouts')"
              >
                <div class="mr-3">
                  <vs-avatar class="m-0" :src="getClientImageURL(workout.client_id)" :text="getClientUserName(workout.client_id)" size="35px" />
                </div>
                <div class="leading-tight">
                  <p class="font-medium">{{getClientUserName(workout.client_id)}}</p>
                  <span
                    :class="{'text-xs':true, 'pre':dayIsPast(workout.due)}"
                  >{{workout.due}} • {{workout.title}}</span>
                </div>
              </li>
            </ul>
          </vx-card>

          <!-- DUE ATTATION -->
          <vx-card
            title="要关注的训练"
            :subtitle="`${WorkoutsNeedsAttention.length} 项`"
            noShadow
            noRadius
            collapseAction
          >
            <ul class="due-attention-list">
              <li
                class="due-attention flex items-center mb-4 cursor-pointer hover:text-primary"
                v-for="(workout, cIdx) in WorkoutsNeedsAttention"
                :key="cIdx"
                @click="$router.push('/clients/' + getClientSlug(workout.client_id) + '/workouts')"
              >
                <div class="mr-3">
                  <vs-avatar class="m-0" :src="getClientImageURL(workout.client_id)" :text="getClientUserName(workout.client_id)" size="35px" />
                </div>
                <div class="leading-tight">
                  <p class="font-medium">{{getClientUserName(workout.client_id)}}</p>
                  <span
                    :class="{'text-xs':true, 'pre':dayIsPast(workout.due)}"
                  >{{workout.due}} • {{workout.title}}</span>
                </div>
              </li>
            </ul>
          </vx-card>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
// import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";

import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

//sample data
//import * as sample from "@/store/samples/coach/home/home.js";

//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";
import datetime from "@/assets/utils/datetime.js";

//components
import HomeWorkouts from "@/views/components/coach/home/HomeWorkouts.vue";
import HomeWorkoutDetail from "@/views/components/coach/home/HomeWorkoutDetail.vue";
import NewClient from "@/views/components/coach/client/NewClient.vue";

/**
 * api calls
 *  api/users/278196
 *  api/groups
 *  api/trainers/38406/clients
 *  api/organizations/35816
 *  api/workouts?active=true&due_today=true&order=updated_at%20DESC&pending=true&rest_day=false&state=pending&trainer_id=38406
 *  api/workouts?hide_from_feed=false&limit=20&order=updated_at%20DESC&page=1&rest_day=false&state=completed&trainer_id=38406
 */

export default {
  mixins: [baseFun],

  data() {
    return {
      clientAddActive: false,
      isNavOpen: false,
      wasSidebarOpen: null,
      showNewClient: false
    };
  },

  created() {
    this.$store.commit("coachPageIdx", 0);

    //获取 一周前 到 3天后的workouts
    let day7before = new Date().addDays(-7);
    let day3after = new Date().addDays(3);
    this.$store.dispatch("getWorkoutsByDate", {
      start_date: this.getDue(day7before),
      end_date: this.getDue(day3after),
      limit: 100,
      order: "due_ASC"
    });
    this.$store.dispatch("getRecentWrokouts", {
      trainer_id: this.activeTrainer.id
    });
    this.$store.dispatch("getClients", { trainer_id: this.activeTrainer.id });
  },

  computed: {
    clientAddValid() {
      return true;
    },

    todayWeekName() {
      let day = new Date();
      return day.toLocaleString("zh-Hans-CN", { weekday: "long" });
    },

    todayDateName() {
      let day = new Date();
      return day.toLocaleString("zh-Hans-CN", {
        month: "long",
        day: "numeric"
      });
    },

    // 今天的workout
    workoutsToday() {
      let vm = this;
      return this.workouts.filter(itm => {
        let client = vm.clients.find(
          clientItem =>
            itm.client_id == clientItem.id &&
            clientItem.trainer_id == vm.activeTrainer.id
        );
        let dueDate = new Date(itm.due);
        let today = new Date();
        let selected =
          client &&
          dueDate.getFullYear() == today.getFullYear() &&
          dueDate.getMonth() == today.getMonth() &&
          dueDate.getDate() == today.getDate();

        // if (selected) {
        //   console.log(itm, client.id, client.trainer_id, dueDate);
        // }
        return selected;
      });
    },

    //3日内的workout
    workoutsReminders() {
      let today = new Date();
      let days = [];
      for (let i=1; i<=3; i++) days.push(this.getDue(today.addDays(i)));
      let vm = this;
      return this.workouts.filter(itm => {
        let client = vm.clients.find(
          clientItem =>
            itm.client_id == clientItem.id &&
            clientItem.trainer_id == vm.activeTrainer.id &&
            !itm.rest_day
        );
        // let dueDate = new Date(itm.due);
        // return dateDiff >= 1 && dateDiff <= 7 && itm.state == "pending";
        // return client && dateDiff >= 1 && dateDiff <= 3;
        return client && days.includes(itm.due);
      });
    },

    //最近发生状态变化的workout
    workoutsRecent() {
      let vm = this;

      // 当前教练下学员的完成、错过的workout
      let aryRecent = this.workouts.filter(item => {
        let client = vm.clients.find(
          clientItem =>
            item.client_id == clientItem.id &&
            clientItem.trainer_id == vm.activeTrainer.id &&
            !item.rest_day
        );

        return client && item.state !== "pending";
      });

      let aryRtn = aryRecent.slice();
      aryRtn.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      return aryRtn.slice(0, 19);
    },

    // 今天前后一周有due的Client
    clientsDuesoon() {
      let today = new Date();
      let msecPerDay = 1000 * 60 * 60 * 24;

      let aryRtn = this.clients.filter(itm => {
        let dueDate = new Date(itm.due);
        let dateDiff = (dueDate - today) / msecPerDay;
        return dateDiff >= -7 && dateDiff <= 7;
      });

      return aryRtn;
    },

    //rate下降的client
    clientsNeedsAttention() {
      let aryRtn = this.clients.filter(
        itm =>
          parseFloat(itm.compliance_rate_for_7_days) <
          parseFloat(itm.compliance_rate_for_30_days)
      );

      return aryRtn;
    },

    //7天内未完成的workout
    WorkoutsNeedsAttention() {
      let vm = this;
      let aryRtn = this.workouts.filter(itm => {
        let client = vm.clients.find(
          clientItem =>
            itm.client_id == clientItem.id &&
            clientItem.trainer_id == vm.activeTrainer.id &&
            !itm.rest_day
        );

        let daysPassed = datetime.getDaysPassed(itm.due);
        return client && itm.state != "complated" && daysPassed > 0 && daysPassed <= 14;
      });
      return aryRtn;
    },

    trainerComplianceRates() {
      let data = [0, 0, 0];
      let trainerId = this.activeTrainer.id;
      let activeClients = this.clients.filter(
        itm => itm.state == "active" && itm.trainer_id == trainerId
      );
      activeClients.forEach(el => {
        data[0] += parseFloat(
          el.compliance_rate_for_7_days ? el.compliance_rate_for_7_days : 0
        );
        data[1] += parseFloat(
          el.compliance_rate_for_30_days ? el.compliance_rate_for_30_days : 0
        );
        data[2] += parseFloat(
          el.compliance_rate_for_90_days ? el.compliance_rate_for_90_days : 0
        );
      });
      if (activeClients.length) {
        data[0] = (data[0] / activeClients.length).toFixed(0);
        data[1] = (data[1] / activeClients.length).toFixed(0);
        data[2] = (data[2] / activeClients.length).toFixed(0);
      }

      return data;
    },

    ...mapState([
      "activeTrainer",
      "activeUser",
      "activeOrganization",
      "organizations",
      "trainers",
      "clients",
      "users",
      "groups",
      "workouts",
      "workout_items"
    ]),

    ...mapGetters([])
  },

  methods: {
    loadContent() {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: "#button-load-more-posts",
        scale: 0.45
      });
      setTimeout(() => {
        this.$vs.loading.close("#button-load-more-posts > .con-vs-loading");
      }, 3000);
    },

    dayIsPast(sDay) {
      let now = Date.parse(new Date());
      let day = Date.parse(new Date(sDay));
      return now > day;
    },

    clientAddAccept() {
      //alert("accepted");
    },
    clientAddCanceled() {
      //alert("canceled");
    },
    clientAddClose() {
      //alert("closed");
    },
    clientAddOpen() {
      this.clientAddActive = true;
    },

    ...mapMutations([]),

    ...mapActions([])
  },
  components: {
    NewClient,
    // videoPlayer,
    HomeWorkouts,
    HomeWorkoutDetail
  },
  mounted() {},
  beforeDestroy() {}
};
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/pages/coachHome.scss";
</style>

<style scoped>
.full-rate {
  font-size: 200%;
  line-height: 100%;
}
.full-rate > span {
  font-size: 45%;
  color: #a4b5c4;
}
.full-rate.complate {
  color: green;
}
.full-rate.incomplate {
  color: #e3434d;
}

.text-xs {
  padding: 2px;
  color: #fa9f47;
}
.text-xs.pre {
  color: #e3434d;
}

.workout-detail{
  margin: 15px 0;
}
</style>
